// Sidebar.jsx
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaRegFileAlt,
  FaRegMoneyBillAlt,
  FaWrench,
  FaRegChartBar,
  FaFileInvoiceDollar,
} from "react-icons/fa";
import home from '../Images/collection.png';
import billing from '../Images/billing.png';

function Sidebar({ isVisible }) {
  const [subMenu, setSubMenu] = useState('');

  const handleSubMenuClick = (menu) => {
    setSubMenu(menu === subMenu ? '' : menu); // Toggle submenu
  };

  return (
    <aside
      className={`fixed h-full w-[190px] bg-white mt-14 z-20 transition-transform duration-1000 ease-in-out transform ${
        isVisible ? 'translate-x-0' : '-translate-x-full'
      }`}
    >
      <nav className="h-full flex flex-col ml-2">
        <div className="flex items-center gap-[15px] py-[20px] border-b-[1px] border-[#EDEDED]/[0.3] px-3">
          <Link
            to="/main/dashboard"
            className={`flex items-center gap-[10px] ${subMenu === 'dashboard' ? 'text-blue-500' : 'text-black'}`}
            onClick={() => handleSubMenuClick('dashboard')}
          >
            <img src={home} alt="home" className="h-5 w-5" />
            <p className="text-[12px] leading-[15px] font-bold">Home</p>
          </Link>
        </div>
        <div
          className={`flex items-center gap-[11px] py-[15px] px-3 cursor-pointer ${subMenu === 'database_retrieve' ? 'text-blue-500' : 'text-black'}`}
          onClick={() => handleSubMenuClick('database_retrieve')}
        >
          <img src={billing} alt="billing" className="h-5 w-5" />
          <p className="text-[12px] leading-[15px] font-bold">Billing</p>
        </div>
        {subMenu === 'database_retrieve' && (
          <div className="ml-6">
            <Link to="database_retrieves" className="flex items-center gap-[1px]">
              <FaRegFileAlt size={15} color="gold" />
              <p className="text-sm cursor-pointer">Retrieve</p>
            </Link>
            <Link to="billview" className="flex items-center gap-[1px]">
              <FaRegMoneyBillAlt size={15} color="gold" />
              <p className="text-sm cursor-pointer">Po-BillView</p>
            </Link>
          </div>
        )}
        <div
          className={`flex items-center gap-[11px] py-[15px] px-3 cursor-pointer ${subMenu === 'utilities' ? 'text-blue-500' : 'text-black'}`}
          onClick={() => handleSubMenuClick('utilities')}
        >
          <FaWrench size={20} />
          <p className="text-[12px] leading-[15px] font-normal">Utilities</p>
        </div>
        <div className="pt-[15px] border-b-[1px] border-[#EDEDED]/[0.3] px-3">
          <div
            className={`flex items-center gap-[11px] py-[15px] cursor-pointer ${subMenu === 'expanses_form' ? 'text-blue-500' : 'text-black'}`}
            onClick={() => handleSubMenuClick('expanses_form')}
          >
            <FaRegFileAlt size={20} />
            <p className="text-[12px] leading-[15px] font-normal">Forms</p>
          </div>
          {subMenu === 'expanses_form' && (
            <div className="ml-6">
              <Link to="expensesform" className="flex items-center gap-[1px]">
                <FaRegFileAlt size={15} color="gold" />
                <p className="text-sm cursor-pointer">Expanses Form</p>
              </Link>
              <Link to="po_list" className="flex items-center gap-[1px] pt-2">
                <FaFileInvoiceDollar size={15} color="gold" />
                <p className="text-sm cursor-pointer">PO_Sheets</p>
              </Link>
              <Link to="testing" className="flex items-center gap-[1px] pt-2">
                <FaRegFileAlt size={15} color="gold" />
                <p className="text-sm cursor-pointer">New Form</p>
              </Link>
            </div>
          )}
          <div
            className={`flex items-center gap-[11px] py-[15px] cursor-pointer ${subMenu === 'charts' ? 'text-blue-500' : 'text-black'}`}
            onClick={() => handleSubMenuClick('charts')}
          >
            <FaRegChartBar size={20} />
            <p className="text-[12px] leading-[15px] font-normal">Charts</p>
          </div>
        </div>
      </nav>
    </aside>
  );
}

export default Sidebar;
