import React, { useState } from 'react';
import logo from '../Images/aablogo.png';
import Sidebar from './Sidebar'; // Import the Sidebar component

const Navbar = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false); // State to control sidebar visibility
  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev); // Toggle the sidebar visibility
  };
  return (
    <>
      <nav className="navbar fixed w-full top-0 z-10 bg-white h-14">
        <div className=' flex'>
          <img
            src={logo}
            alt="Logo"
            className="cursor-pointer w-[42px] h-[40px] rounded-full ml-2 my-2 animate-pulse"
            onClick={toggleSidebar} // Toggle sidebar when logo is clicked
          />
          <div className="container flex justify-between items-center">
            <p className='text-yellow-500 ml-1 mt-1 font-medium text-lg animate-pulse'>BUILDERS</p>
          </div>
        </div>
      </nav>
      {/* Sidebar is conditionally rendered based on isSidebarVisible */}
      <Sidebar isVisible={isSidebarVisible} />
    </>
  );
};

export default Navbar;