import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Sidebar from './Components/Bars/Sidebar';
import Heading from './Components/Heading';
import Form from './Components/Maincantents/Form';
import Tableview from './Components/Maincantents/TableViewExpense';
import Database from './Components/Maincantents/DatabaseExpenses';
import Navbar from './Components/Bars/Navbar';
function App() {
  return (
    <div>
      <Router>
        <Navbar />
        <Sidebar />
        <Heading />
        <Routes>
          <Route path='/' element={<Form />} />
          <Route path='/expensestableview' element={<Tableview />} />
          <Route path='/expenses_database' element={<Database />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
