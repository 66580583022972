import React, { useState } from "react";
import './Heading.css';
import { Link, useLocation } from "react-router-dom";

const Heading = () => {
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);

    const handleLinkClick = (path) => {
        setActiveLink(path);
    };
    console.log("Current Path:", location.pathname); // Check the current path
    return (
        <body className=" bg-yellow-50">
            <div className="topbar-title">
                <h2 className="mb-2 md:-ml-6">
                    <Link
                        className={`link ${activeLink === '/' ? 'active' : ''}`}
                        to='/'
                        onClick={() => handleLinkClick('/')}
                    >
                        Form
                    </Link>
                </h2>
                <h2>
                <Link
                        className={`link ${activeLink === '/expensestableview' ? 'active' : ''}`}
                        to='/expensestableview'
                        onClick={() => handleLinkClick('/expensestableview')}
                    >
                        Tableview
                    </Link>
                </h2>
                <h2>
                    <Link
                        className={`link ${activeLink === '/expenses_database' ? 'active' : ''}`}
                        to='/expenses_database'
                        onClick={() => handleLinkClick('/expenses_database')}
                    >
                        Database
                    </Link>
                </h2>
            </div>
        </body>
    );
}
export default Heading;
